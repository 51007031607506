





























































import { Component, Inject, Vue } from "vue-property-decorator";

import { BackendClient, FileDescriptor } from "ibf-backend";

import { getFileDescriptorFromPath } from "@/utils/FilePathUtil";
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";

@Component({
  components: {
    PieChart,
    BarChart,
  },
})
export default class PSRViewer extends Vue {
  @Inject("backendClient") client!: BackendClient;

  modes = ["count", "probability", "contribution"];
  selectedMode = this.modes[0];

  updateChartData() {
    this.chartData = this.prepareChartData(
      this.psrInfo.cutsets,
      this.selectedMode
    );
    console.log(this.chartData);
  }

  private ifHeaders = [
    {
      value: "index",
      text: "Nr",
    },
    {
      value: "event",
      text: "Event",
    },
    {
      value: "probability",
      text: "P",
    },
    {
      value: "mif",
      text: "MIF",
    },
    {
      value: "cif",
      text: "CIF",
    },
    {
      value: "mif",
      text: "DIF",
    },
    {
      value: "raw",
      text: "RAW",
    },
    {
      value: "rrw",
      text: "RRW",
    },
  ];
  private parametersHeaders = [
    {
      value: "key",
      text: "Item",
    },
    {
      value: "value",
      text: "Value",
    },
  ];

  private cutsetsHeaders = [
    {
      value: "rank",
      text: "Rank",
    },
    {
      value: "probability",
      text: "Probability",
    },
    {
      value: "contribution",
      text: "Contribution",
    },
    {
      value: "order",
      text: "Order",
    },
    {
      value: "events[0]",
      text: "Event 1",
    },
    {
      value: "events[1]",
      text: "Event 2",
    },
    {
      value: "events[2]",
      text: "Event 3",
    },
    {
      value: "events[3]",
      text: "Event 4",
    },
    {
      value: "events[4]",
      text: "Event 5",
    },
  ];

  chartData = [];

  private tab = 0;

  private fileDescriptor: any = undefined;

  private psrInfo: any = undefined;

  private psrLoaded = false;

  public beforeMount() {
    let path = this.$route.params.filePath;
    this.fileDescriptor = getFileDescriptorFromPath(path);
  }

  public mounted() {
    // getInfo about this psr
    this.loadPSR();
  }

  private async loadPSR() {
    if (this.fileDescriptor != null) {
      // load analysis cases
      this.psrInfo = await (
        await this.client.get("/psr/info/" + this.$route.params.filePath)
      ).data;

      // compute chart data

      this.chartData = this.prepareChartData(this.psrInfo.cutsets, "count");

      this.psrLoaded = true;
    }
  }

  prepareChartData(data, mode) {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.order]) {
        acc[item.order] = { count: 0, probabilitySum: 0, contributionSum: 0 };
      }

      acc[item.order].count += 1;
      acc[item.order].probabilitySum += item.probability;
      acc[item.order].contributionSum += item.contribution;

      return acc;
    }, {});

    const chartData = Object.entries(groupedData).map(([order, groupData]) => {
      let value;

      switch (mode) {
        case "count":
          value = groupData.count;
          break;
        case "probability":
          value = groupData.probabilitySum;
          break;
        case "contribution":
          value = groupData.contributionSum;
          break;
        default:
          throw new Error("Invalid mode");
      }

      return { label: `Order ${order}`, value };
    });

    return chartData;
  }
}
