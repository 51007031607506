import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"text-center"},[_c(VCol,{staticClass:"mb-4"},[_c('h1',{staticClass:"display-1 font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.fileDescriptor.path)+" ")])])],1),(_vm.psrLoaded)?_c(VRow,{attrs:{"dense":""}},[_c(VTabs,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v("Cutsets")]),_c(VTab,[_vm._v("Importance factors")]),_c(VTab,[_vm._v("Overview")])],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c(VDataTable,{attrs:{"dense":"","items":_vm.psrInfo.cutsets,"headers":_vm.cutsetsHeaders}})],1),_c(VTabItem,[_c(VDataTable,{attrs:{"dense":"","items":_vm.psrInfo.importanceFactor,"headers":_vm.ifHeaders}})],1),_c(VTabItem,[_c(VDataTable,{attrs:{"disable-pagination":"","hide-default-footer":"","dense":"","items":_vm.psrInfo.parameters,"headers":_vm.parametersHeaders}})],1)],1)],1):_vm._e(),(_vm.psrLoaded)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.modes,"label":"Select mode"},on:{"change":_vm.updateChartData},model:{value:(_vm.selectedMode),callback:function ($$v) {_vm.selectedMode=$$v},expression:"selectedMode"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('pie-chart',{attrs:{"data":_vm.chartData}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('bar-chart',{attrs:{"data":_vm.chartData}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }