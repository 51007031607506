export function getFileDescriptorFromPath(path: String): any {
  const splits = path.split("/");

  // TODO : check for split count

  if (splits[0] == "task") {
    const taskId = splits[1];
    const filePath = splits.slice(2).join("/");

    return {
      context: "TASK",
      taskId: taskId,
      path: filePath,
    };
  } else if (splits[0] == "user") {
    const filePath = splits.slice(1).join("/");

    return {
      context: "USER",
      path: filePath,
    };
  }
}
