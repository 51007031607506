










import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Plotly from "plotly.js";

@Component
export default class BarChart extends Vue {
  @Prop({ type: Array, required: true })
  data!: { label: string; value: number }[];

  mounted() {
    this.renderPlot();
  }

  @Watch("data", { deep: true, immediate: true })
  onDataChange() {
    this.renderPlot();
  }

  renderPlot() {
    const labels = this.data.map((item) => item.label);
    const values = this.data.map((item) => item.value);

    const trace = {
      type: "bar",
      x: labels,
      y: values,
    };

    const layout = {
      title: "Repartition by Order",
      xaxis: {
        title: "Order",
      },
      yaxis: {
        title: "Value",
      },
    };

    Plotly.newPlot(this.$refs.plotlyBarChart, [trace], layout);
  }
}
