










import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Plotly from "plotly.js";

@Component
export default class PieChart extends Vue {
  @Prop({ type: Array, required: true })
  data!: { label: string; value: number }[];

  mounted() {
    this.renderPlot();
  }

  @Watch("data", { deep: true, immediate: true })
  onDataChange() {
    this.renderPlot();
  }

  renderPlot() {
    const labels = this.data.map((item) => item.label);
    const values = this.data.map((item) => item.value);

    const trace = {
      type: "pie",
      labels: labels,
      values: values,
    };

    const layout = {
      title: "Repartition by Order",
    };

    Plotly.newPlot(this.$refs.plotlyPieChart, [trace], layout);
  }
}
